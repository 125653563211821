import { Fragment, useContext, useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import StickyBox from "react-sticky-box";
import { forceCheck } from "react-lazyload";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import { DiscountContext } from "../../context/DiscountProvider";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useSticky } from "../../hooks/useSticky";
import { ComponentType } from "../../constants/ComponentType";
import { isCrawlerBot, getAllUrlParams } from "../../context/helpers";

import { ALButton, PriceString } from "../ALComponents";
import IconArrow from "../icons/svgs/arrow.svg";

import ProductCollection from "./ProductCollection";
import SlideOutMenu from "../SlideOutMenu";
import FiltersCollection from "./FiltersCollection";
import SortsCollection from "./SortsCollection";
import CollectionCard from "./CollectionCard";
import StaticPagination from "./StaticPagination";
import Breadcrumb from "../Breadcrumb";
import IconClose from "../icons/svgs/close-md.svg";

import "./collection.scss";

function CollectionTemplate({
  data,
  products,
  type,
  filterStats,
  filters,
  sortingType,
  onFilter,
  onSort,
  shouldOrderProducts,
}) {
  const { discountInfo } = useContext(DiscountContext);
  const dict = useLocalizedSentenceDict();
  const { collection, content } = data;

  const [isFilterOpenMobile, toggleFilterMobile] = useState(false);
  const [page, setPage] = useState(1);
  const isRobot = isCrawlerBot();

  const rowsPerPage = content.rowsPerPage || 12;
  const nbProductsByRow = useResponsiveWithHydrationFix(2, 3);
  const moduloDevice = useResponsiveWithHydrationFix(4, 5);

  const isCardValid = useCallback(
    (card) =>
      typeof card !== "undefined" &&
      card.title !== "empty" &&
      (card.discountCode === discountInfo?.code || !card.discountCode),
    [discountInfo?.code]
  );

  const cards = useMemo(
    () => content?.cardsContainer?.cards?.filter((card) => isCardValid(card)) || [],
    [content, isCardValid]
  );

  const urlParams = useMemo(() => getAllUrlParams(), []);

  const headerFilterContent = (
    <div className="collection_filters_header">
      <p className="filters_header_title">{dict.get("Filter")}</p>
      <IconClose
        width="35"
        stroke="#2D2927"
        className="filters_header_close"
        onClick={() => toggleFilterMobile(false)}
      />
    </div>
  );

  useEffect(() => {
    if (isFilterOpenMobile) {
      document.getElementById("header").style.zIndex = 999;
    } else {
      document.getElementById("header").style.zIndex = null;
    }
  }, [isFilterOpenMobile]);

  useEffect(() => {
    // fix lazyload on products when refresh
    setTimeout(() => forceCheck(), 300);
  }, [products]);

  useEffect(() => setPage(parseInt(urlParams?.page, 10) || 1), [urlParams, filters]);

  const itemsToDisplay = useMemo(() => {
    if (!products || products.length === 0) return [];
    const allItems = [];

    products.forEach((product, i) => {
      const cardIdx = Math.trunc(i / moduloDevice) - 1;

      if (i !== 0 && i % moduloDevice === 0 && cardIdx < cards.length) {
        const card = cards[cardIdx];
        allItems.push({ isCard: true, ...card });
      }
      allItems.push({ isProduct: true, ...product });
    });

    // for the bot we show the products for specific page
    if (urlParams?.page && urlParams.page > 1) {
      return allItems.slice(
        nbProductsByRow * rowsPerPage * (urlParams.page - 1 || 1),
        nbProductsByRow * rowsPerPage * urlParams.page
      );
    }
    return allItems.slice(0, nbProductsByRow * rowsPerPage * page);
  }, [products, cards, page, nbProductsByRow, rowsPerPage, moduloDevice, urlParams]);

  // BREADCRUMB (MOBILE + DESKTOP)
  const breadcrumbComp = (
    <Breadcrumb
      collectionTitle={content && "title" in content ? content.title : null}
      productTitle={null}
      pageTitle={null}
      levels={content?.breadcrumb?.levels || null}
    />
  );
  const breadcrumbDesktop = useResponsiveWithHydrationFix(null, breadcrumbComp);
  const breadcrumbMobile = useResponsiveWithHydrationFix(breadcrumbComp, null);

  // FILTERS (MOBILE)
  const filtersMobileComp = (
    <>
      <div className="collection_filters" onClick={() => toggleFilterMobile(true)}>
        <span className="collection_filters_title">
          {dict.get("FILTERS")}
          {filters && filters.length > 0 ? (
            <span className="filters_title_count">({filters.length})</span>
          ) : null}
        </span>
        <span className="collection_filters_icon">
          <IconArrow height="11" width="11.8" className="collection_dropdown_arrow" />
        </span>
      </div>
      <SlideOutMenu
        align="right"
        isSlideoutOpen={isFilterOpenMobile}
        toggleSlideout={() => toggleFilterMobile(false)}
        headerContent={headerFilterContent}
      >
        <FiltersCollection
          onFilter={onFilter}
          filterStats={filterStats}
          activeFilters={filters}
          closeMenu={() => toggleFilterMobile(false)}
          productsCount={products && products.length > 0 ? products.length : 0}
        />
      </SlideOutMenu>

      <SortsCollection className="collection_sort" onSort={onSort} sortingType={sortingType} />
    </>
  );
  const filtersSortMobile = useResponsiveWithHydrationFix(filtersMobileComp, null);

  // FILTERS & SORT (DESKTOP)
  const filtersDesktopComp = (
    <StickyBox offsetTop={80} offsetBottom={20} className="collection_content_filters">
      <FiltersCollection onFilter={onFilter} filterStats={filterStats} activeFilters={filters} />
    </StickyBox>
  );
  const filtersDesktop = useResponsiveWithHydrationFix(null, filtersDesktopComp);
  const sortDesktopComp = <SortsCollection onSort={onSort} sortingType={sortingType} />;
  const sortDesktop = useResponsiveWithHydrationFix(null, sortDesktopComp);

  const offsetFilter = useSticky(false);
  const visibleItemCount = itemsToDisplay.length; // cards + products count
  const visibleCardsCount = itemsToDisplay.filter((item) => item.isCard).length;
  const visibleProductCount = visibleItemCount - visibleCardsCount;
  const isLastProductDisplayed = visibleProductCount >= products.length;

  return (
    <div className="collection">
      {breadcrumbDesktop}

      <div
        id="collection_filters_sort"
        className="collection_filters_sort_mobile"
        style={offsetFilter}
      >
        {!isRobot && filtersSortMobile}
      </div>

      <div className="collection_header">
        {content && "type" in content && !["co-creation", "image"].includes(type) ? (
          <h1 className="h2 h2--bold h2--uc m-0">
            {content?.title && <PriceString text={content.title} />}
          </h1>
        ) : null}
        <div className="collection_header_right">
          {products && products.length > 0 ? (
            <p className="collection_count subtext dark-grey">
              {`${products.length} ${
                products.length === 1 ? dict.get("product") : dict.get("products")
              }`}
            </p>
          ) : null}
          {!isRobot && sortDesktop}
        </div>
      </div>

      <div className="collection_content">
        {!isRobot && filtersDesktop}

        <div className="collection_content_products collection_content_products--load-more">
          {itemsToDisplay.map((item, i) => (
            <Fragment key={`coll_template_product_collection_${i}`}>
              {item.isCard && (
                <CollectionCard
                  index={i}
                  collectionHandle={collection.handle}
                  card={item}
                  shouldOrderProducts={shouldOrderProducts}
                />
              )}
              {item.isProduct && (
                <ProductCollection
                  className={`product_collection ${
                    i % 2 === 0 ? "product_collection--left" : "product_collection--right"
                  }`}
                  product={item}
                  collectionHandle={collection.handle}
                  collectionTitle={content?.title}
                  componentType={ComponentType.CP}
                  cpPosition={i + 1}
                  orderProduct={i + 1}
                  shouldOrderProducts={shouldOrderProducts}
                  prices={data?.allShopifyPrices?.edges.filter(
                    (p) => p?.node?.handle === item?.node?.handle
                  )}
                />
              )}
            </Fragment>
          ))}
          <div className="collection_content_products__pagination">
            <StaticPagination
              page={page}
              itemsPerPage={rowsPerPage * nbProductsByRow}
              itemsCount={products?.length}
            />
            Viewing <span className="subtext--bold">{visibleProductCount}</span> of{" "}
            <span className="subtext--bold">{products.length}</span> products
          </div>
          {!isLastProductDisplayed && (
            <div className="collection_content_products__load_more_button_container">
              <ALButton variant="primary" onClick={() => setPage(page + 1)}>
                VIEW MORE
              </ALButton>
            </div>
          )}
        </div>

        {breadcrumbMobile}
      </div>
    </div>
  );
}

CollectionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  filterStats: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  sortingType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  shouldOrderProducts: PropTypes.bool.isRequired,
};

export default withALErrorBoundary({
  name: "CollectionTemplate",
  priority: "P2",
})(CollectionTemplate);
